import { AppRoutes } from "../common/constants/constantEnums";
import LandingPage from "src/pages/home/landingPage";
import { PrivacyPolicy } from "../pages/home/pages/privacyPolicy";
import { createBrowserRouter } from "react-router-dom";

export const router = createBrowserRouter([
    {
        children: [
            {
                element: <LandingPage/>,
                path: "/",
                index: true,
            },
            // {
            //     element: <LoginPage/>,
            //     path: AppRoutes.LOGIN
            // },
            // {
            //     element: <RegisterPage/>,
            //     path: AppRoutes.REGISTER
            // },
            // {
            //     element: <RegistrationForm/>,
            //     path: AppRoutes.REGISTRATION_FORM
            // },
            // {
            //     element: <Profile/>,
            //     path: AppRoutes.PROFILE
            // },
            // {
            //     element: <Admin/>,
            //     path: AppRoutes.ADMIN
            // },
            // {
            //     element: <SubmitPage/>,
            //     path: AppRoutes.SUBMIT
            // },
            // {
            //     element: <Home/>,
            //     path: AppRoutes.HOME
            // },
            {
                element: <PrivacyPolicy/>,
                path: AppRoutes.PRIVACY
            }
        ]
    }
]);