import { Box, IconButton, VStack, useMediaQuery } from "@chakra-ui/react";
import { useCallback, useEffect, useRef, useState } from "react";

import AboutCommunity from "./pages/aboutComminity";
import ArrowUpIcon from "src/resources/icons/ArrowUpIcon";
import { ContactPage } from "./pages/contactUs";
import HomeNavbar from "./homeNavbar";
import LandingPagePoster from "./pages/landingPagePoster";
import RegisteredSansthas from "./pages/sansthas/registeredSansthas";
import { UpcomingEvents } from "./pages/events/upcomingEvents";

const LandingPage: React.FC = () => {
    const [showScrollButton, setShowScrollButton] = useState(false);
    const [activeSection, setActiveSection] = useState<string>("");

    const [isMobile] = useMediaQuery("(max-width: 426px)");
    const [isTablet] = useMediaQuery("(min-width: 426px) and (max-width: 768px)");

    const aboutRef = useRef<HTMLDivElement>(null);
    const eventsRef = useRef<HTMLDivElement>(null);
    const sansthaRef = useRef<HTMLDivElement>(null);
    const contactRef = useRef<HTMLDivElement>(null);
    const landingPagePosterRef = useRef<HTMLDivElement>(null);

    const scrollToSection = useCallback((ref: React.RefObject<HTMLDivElement>) => {
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: "smooth" });
        }
    }, []);

    const handleScroll = useCallback(() => {
        setShowScrollButton(window.scrollY > 100);
    }, []);

    const scrollToTop = useCallback(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [handleScroll]);

    useEffect(() => {
        const sections = [
            { ref: aboutRef, id: "About us" },
            { ref: eventsRef, id: "Events" },
            { ref: sansthaRef, id: "Sanstha" },
            { ref: contactRef, id: "Contact us" },
            { ref: landingPagePosterRef, id: "Landing Page Poster" },
        ];

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const active = sections.find(
                            (section) => section.ref.current === entry.target
                        );
                        if (active) {
                            setActiveSection(active.id);
                        }
                    }
                });
            },
            { threshold: 0.3 }
        );

        sections.forEach(({ ref }) => {
            if (ref.current) {
                observer.observe(ref.current);
            }
        });

        return () => {
            sections.forEach(({ ref }) => {
                if (ref.current) {
                    observer.unobserve(ref.current);
                }
            });
        };
    }, []);

    const backgroundColor = (showScrollButton || isMobile || isTablet) ? "white" : "transparent";
    const topPosition = (showScrollButton || isMobile || isTablet) ? "0" : "8";

    return (
        <VStack h="100%" width="100%">
            <Box
                key={`${backgroundColor}-${topPosition}`}
                mt={-2}
                position="sticky"
                top={topPosition}
                zIndex="1000"
                width="100%"
                background={backgroundColor}
                transition="top 0.3s ease-in-out, background 0.3s ease-in-out"
            >
                <HomeNavbar
                    isScrolled={showScrollButton}
                    activeSection={activeSection}
                    scrollToAbout={() => scrollToSection(aboutRef)}
                    scrollToEvents={() => scrollToSection(eventsRef)}
                    scrollToSanstha={() => scrollToSection(sansthaRef)}
                    scrollToContact={() => scrollToSection(contactRef)}
                />
            </Box>

            <Box bgColor="#F8F8F8" h="100%" w="100%" ref={landingPagePosterRef}>
                <LandingPagePoster />
            </Box>
            <Box h="100%" w="100%" ref={aboutRef}>
                <AboutCommunity />
            </Box>
            <Box h="100%" w="100%" ref={eventsRef}>
                <UpcomingEvents />
            </Box>
            <Box h="100%" w="100%" ref={sansthaRef}>
                <RegisteredSansthas />
            </Box>
            <Box h="100%" w="100%" ref={contactRef}>
                <ContactPage />
            </Box>

            {showScrollButton && (
                <IconButton
                    position="fixed"
                    bottom="20px"
                    right={(isMobile || isTablet)? "45px" : "35px"}
                    background="white"
                    boxShadow="0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 8px 16px 0px rgba(0, 0, 0, 0.08)"
                    borderRadius="12px"
                    border="1px solid #4A2C2A"
                    zIndex="1000"
                    aria-label="Go to top"
                    icon={<ArrowUpIcon />}
                    onClick={scrollToTop}
                />
            )}
        </VStack>
    );
};

export default LandingPage;