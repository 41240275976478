import { ComponentStyleConfig } from "@chakra-ui/react";

const customHeadingStyles : ComponentStyleConfig  = {
    baseStyle : {
        fontFamily: "PP Neue Montreal",
        lineHeight: "100%",
        fontStyle: "normal"
    },
    defaultProps : {
        variant : "h1"
    },
    variants : {
        d1 : {
            fontSize : "48px",
            fontWeight : "medium",
            lineHeight : "161.8%"
        },
        d2 : {
            fontSize : "42px",
            fontWeight : "medium",
            lineHeight : "161.8%"
        },
        h1 : {
            fontSize : "38px",
            fontWeight : "medium",
            lineHeight : "161.8%"
        },
        h2 : {
            fontSize : "36px",
            fontWeight : "medium",
            lineHeight : "161.8%"
        },
        h3 : {
            fontSize : "32px",
            fontWeight : "bold",
            lineHeight : "161.8%"
        },
        h4 : {
            fontSize : "28px",
            fontWeight : "medium",
            lineHeight : "161.8%"
        },
        h5 : {
            fontSize : "24px",
            fontWeight : "semibold",
            lineHeight : "100%"
        },
        h6 : {
            fontSize : "20px",
            fontWeight : "semibold",
            lineHeight : "100%"
        },
        h7 : {
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "normal",
        },
    },
};

export default customHeadingStyles;