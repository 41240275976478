import './index.css';

import { CSSReset, ChakraProvider } from '@chakra-ui/react';

import { Provider } from "react-redux";
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { router } from './appConfig/AppRouter';
import store from './store/store';
import theme from "./theme/theme";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
        <Provider store={store}>
            <ChakraProvider theme={theme}>
                <CSSReset/>
                    <RouterProvider router={router} />
            </ChakraProvider>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
