import * as React from "react";

import { JSX } from "react/jsx-runtime";

const ArrowUpIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g id="arrow-up-03">
            <path id="Vector" d="M22.5 5H7.5" stroke="#4A2C2A" stroke-width="1.5" stroke-linecap="round" />
            <path id="Vector_2" d="M15 10V25" stroke="#4A2C2A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path id="Vector_3" d="M20 15C20 15 16.3176 10 15 10C13.6823 9.99999 10 15 10 15" stroke="#4A2C2A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </g>
    </svg>

);
export default ArrowUpIcon;
