import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./commonSlider.scss";

import Slider, { Settings } from 'react-slick';

import React from "react";

type CommonSliderProps = {
    sliderRef : React.RefObject<Slider>;
    component: React.ReactNode[];
} & Settings

export const CommonSlider: React.FC<CommonSliderProps> = (props) => {
    const { sliderRef, slidesToShow, component, className, infinite, dots, arrows, autoplay,
        autoplaySpeed  } = props;

    const settings: Settings = {
        autoplay: autoplay,
        autoplaySpeed: autoplaySpeed,
        dots: dots,
        infinite: infinite,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                slidesToShow: slidesToShow,
                },
            },
            {
                breakpoint: 768,
                settings: {
                slidesToShow: slidesToShow,
                },
            },
        ],
        arrows: arrows,
    };
    
    return (
        <Slider className={className} ref={sliderRef} {...settings}>
            {component.map((comp)=>comp)}
        </Slider>
    );
} 