import {
    ThemeConfig,
    type
    ThemeOverride,
    extendTheme,
} from "@chakra-ui/react";
import borderRadius from "./borderRadius";
import colors from "./colors";
import customHeadingStyles from "./customHeadingStyles";
import semanticTokens from "./semanticTokens";
import spacing from "./spacing";
import textStyles from "./textStyles";
import { tabsTheme } from "./tabs";

const config: ThemeConfig = {
    initialColorMode: "light",
    useSystemColorMode: false,
};

const Button = {
    baseStyle: {
        _focus: { boxShadow: "none" },
        borderRadius: "0.5rem",
        fontWeight: "regular",
        textTransform: "capitalize"
    },
    variants: {
        "dark": {
            _disabled: {
                _hover: { bg: "gray.800" },
            },
            _hover: {
                _disabled: {
                    bg: "gray.600",
                },
                bg: "gray.800",

            },
            bg: "gray.600",
            color: "white"
        },
        "mks": {
            _disabled: {
                _hover: { bg: "#7c4a46" },
            },
            _hover: {
                _disabled: {
                    bg: "#7c4a46",
                },
                bg: "#7c4a46",

            },
            bg: "#4A2C2A",
            color: "white"
        },
        "mks-white": {
            _active: {
                bg: "gray.100",
                fontWeight: "bold"
            },
            _disabled: {
                _hover: { bg: "white" },
            },
            _hover: {
                _disabled: {
                    bg: "gray.100",
                },
                bg: "#ffa54a",

            },
            bg: "#FFCA964A",
            color: "#4A2C2A"
        },
        "mks-tabs": {
            _active: {
                bg: "#4A2C2A",
                fontWeight: "bold",
                color: "white",
            },
            _disabled: {
                _hover: { bg: "white" },
            },
            _hover: {
                _disabled: {
                    bg: "gray.100",
                },
                bg: "white",

            },
            border: "1px solid rgba(108, 117, 125, 0.51)",
            borderRadius: "14px",
            bg: "#FFFFFF63",
            color: "#000000",
        }
    },
};

const theme : ThemeOverride = extendTheme({
    breakpoints : {
        lg: "1024px",
        md: "768px",
        sm: "375px",
        xl: "1440px",
    },
    colors,
    components: {
        Heading: customHeadingStyles,
        Button,
        Tabs: tabsTheme
    },
    config,
    fonts: {
        body: "PP Neue Montreal",
        heading: "PP Neue Montreal",
        mono: "PP Neue Montreal",
    },
    radii : {
        ...borderRadius.radii
    },
    semanticTokens,
    shadows:{
        popShadowLight: "0px 0px 10px rgba(48, 48, 48, 0.10)",
        rightShadowLight:"4px 0px 16px rgba(18, 27, 34, 0.08)",
    },
    space : {
        ...spacing.space
    },
    textStyles
});

export default theme;