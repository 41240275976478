import {Box, Flex, HStack, Image, Link, Text, VStack, useMediaQuery} from '@chakra-ui/react';

import CONTACT_US_BG from "../../../resources/images/1682959610074-min.jpg"
import EMAIL_ICON from "../../../resources/icons/Email_Icon.svg";
import FACEBOOK_ICON from "../../../resources/icons/facebook-02.svg";
import INSTAGRAM_ICON from "../../../resources/icons/instagram.svg";
import {MksLogo} from "../../../resources/icons";
import {Link as ReactRouterLink} from "react-router-dom";
import WHATSAPP_ICON from "../../../resources/icons/whatsapp.svg";

export const ContactPage: React.FC = () => {
    const [isMobileOrTablet] = useMediaQuery('(max-width: 768px)');

    return (
        <Flex height="100%" direction="column" background="#FFBC780F">
            <Box
                width="100%"
                h="390px"
                color="white"
                textAlign="center"
                sx={{
                    background: `linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.6) ), url('${CONTACT_US_BG}')`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover"
                }}
            >
                <VStack spacing="20px" mt="122px">
                    <VStack width="100%">
                        <Text fontSize="32px" fontWeight="700">Get in Touch with Us</Text>
                        <Text fontSize="20px" fontWeight="500">Feel free to drop any inquiries or questions, we'll get
                            back to you shortly.</Text>
                    </VStack>
                    <HStack spacing="10px">
                        <Box
                            width="56px"
                            height="56px"
                            padding="16px"
                            alignItems="center"
                            borderRadius="12px"
                            background="#F8F7F7"
                            boxShadow="0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 8px 16px 0px rgba(0, 0, 0, 0.08)"
                            _hover={{
                                opacity: 0.8,
                                transform: 'scale(1.05)',
                                transition: "transform 0.3s ease, opacity 0.3s ease"
                            }}
                        >
                            <Image
                                src={EMAIL_ICON}
                            />
                        </Box>
                        <Box
                            width="56px"
                            height="56px"
                            padding="16px"
                            alignItems="center"
                            borderRadius="12px"
                            background="#F8F7F7"
                            boxShadow="0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 8px 16px 0px rgba(0, 0, 0, 0.08)"
                            _hover={{
                                opacity: 0.8,
                                transform: 'scale(1.05)',
                                transition: "transform 0.3s ease, opacity 0.3s ease"
                            }}
                        >
                            <Image
                                src={FACEBOOK_ICON}
                            />
                        </Box>
                        <Box
                            width="56px"
                            height="56px"
                            padding="16px"
                            alignItems="center"
                            borderRadius="12px"
                            background="#F8F7F7"
                            boxShadow="0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 8px 16px 0px rgba(0, 0, 0, 0.08)"
                            _hover={{
                                opacity: 0.8,
                                transform: 'scale(1.05)',
                                transition: "transform 0.3s ease, opacity 0.3s ease"
                            }}
                        >
                            <Image
                                src={WHATSAPP_ICON}
                            />
                        </Box>
                        <Box
                            width="56px"
                            height="56px"
                            padding="16px"
                            alignItems="center"
                            borderRadius="12px"
                            background="#F8F7F7"
                            boxShadow="0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 8px 16px 0px rgba(0, 0, 0, 0.08)"
                            _hover={{
                                opacity: 0.8,
                                transform: 'scale(1.05)',
                                transition: "transform 0.3s ease, opacity 0.3s ease"
                            }}
                        >
                            <Image
                                src={INSTAGRAM_ICON}
                            />
                        </Box>
                    </HStack>
                </VStack>
            </Box>
            {/*footer-section*/}
            <Box alignItems={{sm: "center", lg: "flex-start", md: "flex-start"}} mx="40px" mt="100px" mb="32px">
                <Flex
                    direction={{base: 'column', md: 'row'}}
                    justify="space-between"
                    align="center"
                    alignItems={{sm: "center", lg: "flex-start", md: "flex-start"}}
                    justifyContent="space-between"
                >
                    <VStack alignItems="flex-start" align="start" mb={{base: 6, md: 0}} spacing="1rem">
                        <HStack>
                            <Box alignItems="center" height="60px" width="60px">
                                <MksLogo width={16} height="auto"/>
                            </Box>
                            <Text width="195px" fontSize="24px" fontWeight="500" color="#4A2C2A">Maurya Kushwaha Samaj,
                                Thane</Text>
                        </HStack>
                        <Text hidden={isMobileOrTablet} color={"#6C757D"} ml="1rem" fontSize="20px" fontWeight="500">©
                            2024, All rights reserved</Text>
                    </VStack>

                    <VStack spacing={2} alignItems={{base: "center", md: "flex-start", lg: "flex-start"}}>
                        <Text fontSize="lg" fontWeight="bold">Quick Links</Text>
                        <Link color={"#6C757D"} href="/">About us</Link>
                        <Link color={"#6C757D"} href="/">Events</Link>
                        <Link color={"#6C757D"} href="/">Contact us</Link>
                    </VStack>

                    {isMobileOrTablet ?
                        <>
                            <VStack mt="1rem" alignItems="center" spacing={2}>
                                <Text fontSize="lg" fontWeight="bold">Mob Social Media</Text>
                                <HStack spacing={2} alignItems="center">
                                    <Link color={"#6C757D"} href="#">Facebook</Link>
                                    <Link color={"#6C757D"} href="#">Instagram</Link>
                                    <Link color={"#6C757D"} href="#">YouTube</Link>
                                </HStack>
                            </VStack>
                            <VStack mt="1rem" alignItems="center" spacing={2}>
                                <Text fontSize="lg" fontWeight="bold">Legal</Text>
                                <HStack>
                                    <Link color={"#6C757D"} as={ReactRouterLink} to="/privacy-policy">Privacy Policy</Link>
                                    <Link color={"#6C757D"} href="#">Legal documents</Link>
                                </HStack>
                                <Text mt={{base: 4, md: 0}} color={"#6C757D"}>© 2024, All rights reserved</Text>
                            </VStack>
                        </>

                        :
                        <>
                            <VStack spacing={2} alignItems="flex-start">
                                <Text fontSize="lg" fontWeight="bold">Web Social Media</Text>
                                <Link color={"#6C757D"} href="#">Facebook</Link>
                                <Link color={"#6C757D"} href="#">Instagram</Link>
                                <Link color={"#6C757D"} href="#">YouTube</Link>
                            </VStack>

                            <VStack spacing={2} alignItems="flex-start">
                                <Text fontSize="lg" fontWeight="bold">Legal</Text>
                                <Link color={"#6C757D"} as={ReactRouterLink} to="/privacy-policy">Privacy Policy</Link>
                                <Link color={"#6C757D"} href="#">Legal documents</Link>
                            </VStack>
                        </>
                    }

                </Flex>
                <Flex justifyContent={"center"} mt={{base: 4, md: 8, lg: 12}}>
                    <HStack ml={4}>
                        <Text fontSize="md" fontWeight="500">Powerd By</Text>
                        <Text fontSize="md" fontWeight="bold">VESIS</Text>
                    </HStack>
                </Flex>
            </Box>
        </Flex>
    );
};

