import {Box, GridItem, HStack, Img, Link, SimpleGrid, Text} from "@chakra-ui/react";
import {ScrollRestoration} from "react-router-dom";
import {ContactPage} from "./contactUs";
import LEFT_ARROW_ENABLED from "../../../resources/icons/button-arrow-left-enabled.svg";

export const PrivacyPolicy = () => {

    const menuItems = [
        {label: 'Back to Home', color: '#4A2C2A', link: '/', button: LEFT_ARROW_ENABLED}
    ];

    const privacySections = [
        {
            title: 'Onboarding Section: Privacy Policy',
            content:
                'Welcome to our platform! We value your privacy and are committed to protecting your personal information. This onboarding section outlines how we collect, use, and safeguard your data as you begin your journey with us.',
        },
        {
            title: 'Information we collect',
            content: (
                <>
                    During the onboarding process, we collect various types of information to create your account and
                    enhance your experience. This includes:
                    <br/>
                    <strong>Personal Information:</strong> Your name, email address, and contact details.
                    <br/>
                    <strong>Account Information:</strong> Username, password, and preferences.
                    <br/>
                    <strong>Usage Data:</strong> Information about how you interact with our services, including device
                    information and log data.
                </>
            ),
        },
        {
            title: 'How we use your information',
            content: (
                <>
                    We use the information collected during onboarding to:
                    <br/>
                    - Create and manage your account.
                    <br/>
                    - Provide personalized content and recommendations.
                    <br/>
                    - Communicate with you about your account and our services.
                    <br/>
                    - Improve our platform based on user feedback and usage patterns.
                </>
            ),
        },
        {
            title: 'Data security',
            content:
                'We take data security seriously. Your information is stored securely using industry-standard encryption methods. Access to your personal data is restricted to authorized personnel only, ensuring that your information remains confidential.',
        },
        {
            title: 'Your rights',
            content: (
                <>
                    As a user, you have rights regarding your personal data, including:
                    <br/>
                    - The right to access your information.
                    <br/>
                    - The right to request corrections to your data.
                    <br/>
                    - The right to delete your account and personal information.
                </>
            ),
        },
        {
            title: 'Changes to this policy',
            content: (
                <>
                    We may update this privacy policy from time to time. Any changes will be communicated to you through
                    our platform. We encourage you to review this policy periodically to stay informed about how we are
                    protecting your information.
                    <br/>
                    <br/>
                    Thank you for choosing our platform! We look forward to providing you with a secure and enjoyable
                    experience.
                </>
            ),
        },
    ];
    return (
        <div>
            <ScrollRestoration/>
            <Box h="100%" width="100%">
                <SimpleGrid columns={{base: 1, md: 6}}>
                    <GridItem
                        colSpan={1}
                        px={"20px"}
                        pb={{base: "20px", lg: "40px"}}
                        pt={{base: "20px", lg: "40px"}}
                    >
                        {menuItems ? menuItems.map((item, index) => (
                            <Link key={index} fontSize="20px" fontWeight="500" color={item.color} href={item.link}>
                                <HStack>
                                    <Img src={item.button} alt="left arrow" width={6} style={{marginRight: "2px"}}/>
                                    <Text>{item.label}</Text>
                                </HStack>
                            </Link>
                        )) : null}
                    </GridItem>
                    <GridItem
                        colSpan={5}
                        px={{base: "20px", md: "40px", lg: "60px"}}
                        pb={{base: "40px", md: "60px", lg: "120px"}}
                        pt={{base: "0px", md: "20px", lg: "40px"}}
                        borderLeft={"2px solid #EBEBEB"}>
                        {privacySections ? privacySections.map((section, index) => (
                            <Box key={index} pb={"32px"}>
                                <Text fontSize="xl" fontWeight="bold" color="#4A2C2A">
                                    {section.title}
                                </Text>
                                <Text fontSize="md" mt={2}>
                                    {section.content}
                                </Text>
                            </Box>
                        )) : null}
                    </GridItem>
                </SimpleGrid>
            </Box>
            <Box h="100%" w="100%">
                <ContactPage/>
            </Box>
        </div>
    );
}
