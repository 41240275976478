import { Button, Flex, Heading, Image, Input, InputGroup, InputLeftElement, useBreakpointValue } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';

import { Carousel } from 'src/common/carousel';
import LEFT_ARROW from "../../../../resources/icons/button-arrow-left-enabled.svg";
import LEFT_ARROW_DISABLED from "../../../../resources/icons/button-arrow-left-disabled.svg";
import RIGHT_ARROW from "../../../../resources/icons/circle-arrow-right-01.svg";
import RIGHT_ARROW_DISBLED from "../../../../resources/icons/button-arrow-right-disabled.svg";
import { SansthaCard } from './sansthaCard';
import { SearchIcon } from '@chakra-ui/icons';
import Slider from 'react-slick';

const RegisteredSansthas: React.FC = () => {
    const sliderRef = useRef<(Slider | null)>(null);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [disableNext, setDisableNext] = useState(false);
    const [disablePrev, setDisablePrev] = useState(false);
    
    const slidesToShow = useBreakpointValue({
        xl: 3,
        lg: 3,
        md: 2,
        sm: 2
    });

    const scrollNext = () => {
        if(disableNext) return;
        sliderRef?.current?.slickNext();
    };

    const scrollPrevious = () => {
        if(disablePrev) return;
        sliderRef?.current?.slickPrev();
    };

    
    useEffect(() => {
        if(currentSlide === 0) {
            setDisablePrev(true);
        }
        else if(5-currentSlide === slidesToShow){
            setDisableNext(true);
        } else {
            setDisableNext(false);
            setDisablePrev(false);
        }
    }, [currentSlide, slidesToShow]);

    return (
        <Flex direction="column" paddingY={{ base: 8, md: 12, lg: 14}} paddingX="2rem" background="rgba(255, 188, 120, 0.06)" height="100%">
            <Flex direction="row" alignItems="center" justifyContent="space-between" width="100%">
                <Heading>Recent Events</Heading>
                <Button _hover={{ background: "#FF7E6B" }} color="white" background="#FF7E6B">
                    View all
                </Button>
            </Flex>
            <Flex marginY="30px">
                <InputGroup
                    width="289.31px"
                    height="40px"
                    border="1px solid #6C757D"
                    borderRadius="8px"
                >
                    <InputLeftElement>
                        <SearchIcon/>
                    </InputLeftElement>
                    <Input
                        placeholder='Search any sanstha'
                    />
                </InputGroup>
            </Flex>
            <Carousel
                CardComponent={SansthaCard}
                sliderRef={sliderRef}
                setCurrentSlide={(slideNumber) => setCurrentSlide(slideNumber)}
                slidesToShow={slidesToShow ?? 3}
            />
            <Flex justifyContent="center" gap={4} marginTop="60px">
                <Image
                    onClick={scrollPrevious}
                    src={disablePrev ? LEFT_ARROW_DISABLED : LEFT_ARROW}
                    _hover={{
                        opacity: 0.8,
                        transform: 'scale(1.05)',
                        transition:"transform 0.3s ease, opacity 0.3s ease"
                    }}
                />
                <Image
                    onClick={scrollNext}
                    src={disableNext ? RIGHT_ARROW_DISBLED :RIGHT_ARROW}
                    _hover={{
                        opacity: 0.8,
                        transform: 'scale(1.05)',
                        transition:"transform 0.3s ease, opacity 0.3s ease"
                    }}
                />
            </Flex>
        </Flex>
    );
}

export default RegisteredSansthas;