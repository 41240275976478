import "../../../common/carousel.scss"

import { Box, GridItem, Image, SimpleGrid, Text, VStack, useMediaQuery } from "@chakra-ui/react";
import { EnglishLabels, LANDING_PAGE_LABELS_ENG } from "src/common/constants/englishLabels";
import { HindiLabels, LANDING_PAGE_LABELS_HIN } from "src/common/constants/hindiLabels";

import { CommonSlider } from "src/common/commonSlider";
import { RootState } from "src/store/store";
import Slider from "react-slick";
import carousel_1 from "../../../resources/images/carousel_1.jpg";
import carousel_2 from "../../../resources/images/carousel_2.jpg";
import carousel_3 from "../../../resources/images/carousel_3.jpg";
import { useRef } from "react";
import { useSelector } from "react-redux";

const LandingPagePoster: React.FC = () => {
    const [isMobile] = useMediaQuery("(max-width: 426px)");
    const [isTablet] = useMediaQuery("(min-width: 426px) and (max-width: 768px)");
    const images = [carousel_1, carousel_2, carousel_3];
    const globalInfo = useSelector((state: RootState) => state.globalInfo);
    const { globalLanguage } = globalInfo;
    const isHindiLabels = globalLanguage === "HI";
    const sliderRef = useRef<(Slider | null)>(null);

    return (
        <Box pb={12} bgColor="#F8F8F8" mb={isMobile? 24 : 0} mt={(isMobile) ? 10 : isTablet ? 6 : "6.5rem"} height={isMobile || isTablet ? "100%" : "100%"} w="100%">
            <SimpleGrid pt={16} templateColumns={(isMobile || isTablet)? "100%" : "45% 55%"} columns={(isMobile || isTablet) ? 1 : 2} height="100%" width="100%">
                <GridItem height="100%" width="100%">
                    <VStack height="100%" width="90%" pl={"4.2rem"} py={4}>
                        <Text
                            textAlign={"start"}
                            fontSize={isMobile? "35px" : "70px"}
                            ml={10}
                            fontWeight={"400"}
                            w={"100%"}
                            lineHeight="normal"
                            color={"#4A2C2A"}
                        >
                            {isHindiLabels ? HindiLabels.Mourya_Community_Label : EnglishLabels.Mourya_Community_Label}
                        </Text>
                        <Text
                            textAlign={"start"}
                            w={"100%"}
                            ml={10}
                            fontSize={isMobile? "18px" : "36px"}
                            fontWeight={400}
                            color={"08090A"}
                        >
                            {isHindiLabels ? LANDING_PAGE_LABELS_HIN.CELEBRATING_UNITY_AND_TRADITION : LANDING_PAGE_LABELS_ENG.CELEBRATING_UNITY_AND_TRADITION}
                        </Text>
                    </VStack>
                </GridItem>
                <GridItem
                    height="100%"
                    width="96%"
                >
                    <Box ml={(isMobile || isTablet)? 10 : 0}>
                        <CommonSlider
                            component={images.map((image: any, index: number) => (
                                <Image key={index} src={image} alt={`image-${index}`} width="100%" height="auto" />
                            ))}
                            arrows={false}
                            dots={true}
                            infinite={true}
                            swipeToSlide={true}
                            speed={500}
                            sliderRef={sliderRef}
                            autoplay={true}
                            autoplaySpeed={3500}
                            slidesToShow={1}
                        />
                    </Box>
                </GridItem>

            </SimpleGrid>
        </Box>
    );
};

export default LandingPagePoster;