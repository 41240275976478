export enum Numbers {
    ONE = 1,
    FOUR = 4
}

export enum AppRoutes {
    LOGIN= "/login",
    REGISTER = "/register",
    SUBMIT = "/submit",
    REGISTRATION_FORM = "/registration-form",
    PROFILE = "/profile",
    ADMIN = "/admin",
    HOME = "/home",
    PRIVACY = "/privacy-policy"
}