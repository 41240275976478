import "./styles.css"

import { Box, Button, HStack, IconButton, useMediaQuery } from "@chakra-ui/react";
import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import { ReactElement, useState } from "react";

import { GlobalLangSelector } from "src/common/globalLangSelector";
import { MksLogo } from "src/resources/icons";
import SideDrawer from "./sideDrawer";

export interface NavMenu {
    name: string;
    onClick: () => void;
}

interface NavbarProps {
    scrollToAbout: () => void;
    scrollToEvents: () => void;
    scrollToSanstha: () => void;
    scrollToContact: () => void;
    isScrolled: boolean;
    activeSection: string;
}


const HomeNavbar: React.FC<NavbarProps> = (props: NavbarProps) => {
    const { scrollToAbout, scrollToEvents, scrollToContact, isScrolled, activeSection } = props;
    const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
    const [isMobile] = useMediaQuery("(max-width: 426px)");
    const [isTablet] = useMediaQuery("(min-width: 426px) and (max-width: 768px)");

    const navButtons: NavMenu[] = [
        {
            name: "About us",
            onClick: scrollToAbout
        },
        {
            name: "Events",
            onClick: scrollToEvents
        },
        // {
        //     name: "Sanstha",
        //     onClick: scrollToSanstha
        // },
        {
            name: "Contact us",
            onClick: scrollToContact
        },
        // {
        //     name: "Join us",
        //     onClick: () => navigate(AppRoutes.HOME)
        // },
    ];

    const handleNavClick = (nav: { name: string, onClick: () => void }): void => {
        nav.onClick();
        if(sideDrawerOpen) {
            setSideDrawerOpen(false);
        }
    }

    const renderLangSelectorMenuRenderer = (): ReactElement => {
        return (
            <>
                <Box hidden={!(isMobile || isTablet)}>
                    <GlobalLangSelector />
                </Box>

                <HStack
                    px={2}
                    alignItems="center"
                    height="4rem"
                    bg="white"
                    borderRadius="10px"
                    boxShadow={(isMobile || isTablet || isScrolled) ? "" : "0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 8px 16px 0px rgba(0, 0, 0, 0.08)"}
                >
                    {isMobile || isTablet ?
                        <IconButton bgColor="transparent" aria-label="HamburgerIcon">
                            {sideDrawerOpen ?
                                <CloseIcon 
                                    boxSize={5}
                                    onClick={() => setSideDrawerOpen(false)}
                                />
                                : 
                                <HamburgerIcon
                                    boxSize={8}
                                    boxShadow="none"
                                    onClick={() => setSideDrawerOpen(true)}
                                />
                            }   
                        </IconButton>
                        :
                        <GlobalLangSelector />
                    }
                </HStack>
            </>
        )
    }

    return (
        <HStack
            py={2}
            px={[0, 0, 0, 10]}
            transition="bg 0.3s ease"
            bg={(isMobile || isTablet || isScrolled) ? "white" : "transparent"}
            justifyContent="space-evenly"
            position="absolute"
            w="100%"
            boxShadow={(isMobile || isTablet || isScrolled) ? "0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 8px 16px 0px rgba(0, 0, 0, 0.08)" : ""}
        >
            <Box
                borderRadius="10px"
                bg="white"
                height="4rem"
                width="4rem"
                boxShadow={(isMobile || isTablet || isScrolled) ? "" : "0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 8px 16px 0px rgba(0, 0, 0, 0.08)"}
            >
                <MksLogo />
            </Box>
            <HStack
                hidden={isMobile || isTablet}
                w="55%"
                px={2}
                height="4rem"
                bg="white"
                borderRadius="10px"
                justifyContent="space-around"
                paddingX={10}
                boxShadow={(isMobile || isTablet || isScrolled) ? "" : "0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 8px 16px 0px rgba(0, 0, 0, 0.08)"}
            >
                {navButtons.map((nav) =>
                    <Button
                        className="navbar-buttons"
                        backgroundColor={nav.name === activeSection ? "#4A2C2A" : "white"}
                        color={nav.name === activeSection ? "white" : "#4A2C2A"}
                        onClick={() => handleNavClick(nav)}
                        fontWeight={500}
                    >
                        {nav.name}
                    </Button>
                )}
            </HStack>
            {(isMobile || isTablet) ?
                <HStack w="60%" justifyContent="flex-end">
                    {renderLangSelectorMenuRenderer()}
                </HStack>
                :
                renderLangSelectorMenuRenderer()}
            {((isMobile || isTablet ) && sideDrawerOpen) ? 
                <SideDrawer
                sideDrawerOpen={sideDrawerOpen}
                onClose={() => setSideDrawerOpen(false)}
                menu={navButtons}
                activeSection={activeSection}
                handleNavClick={(nav: NavMenu) => handleNavClick(nav)}
            />
            : <></>}
        </HStack>
    );
};

export default HomeNavbar;
