const borderRadius = {
    radii: {
        lg: "16px",
        m : "10px",
        ml: "12px",
        round: "999px",
        s: "6px",
        sharp: "0px",
        sm: "8px",
        xl: "20px",
        xs:"4px",
        xxs: "2px",
    },
};

export default borderRadius;