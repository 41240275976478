import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { GlobalState } from "src/types/storeTypes/global";

;

const initialState: GlobalState = {
    globalLanguage: "EN",
};

const setGlobalInfoReducer = (_state: GlobalState,
    action : PayloadAction<GlobalState>) : GlobalState => {
    return action.payload;
};

const globalInfoSlice = createSlice({
    initialState,
    name: "global",
    reducers: {
        setGlobalInfo: setGlobalInfoReducer,
    },
});

export const { setGlobalInfo } = globalInfoSlice.actions;
export default globalInfoSlice.reducer;