export enum EnglishLabels {
    Mourya_Community_Label = "Maurya Kushwaha Samaj, Thane.",
    Mourya_Community_Label2 = "Welcome to our digital community, join and empower us.",
    Register = "Register",
    Login = "Login",
    Welcome_Label = "Welcome to the digital world of Maurya Kushwaha Samaj, Thane.",
    Proceed = "Proceed",
    Dont_Have_Account = "Don't have an account?",
    Already_Have_Account = "Already have an account ?",
    Enter_Email = "Enter your email",
    Enter_Password = "Enter password",
    Confirm_Password = "Confirm password",
    Enter_DOB = "Enter your D.O.B",
    Community_Joining_Que = "REASON FOR JOINING COMMUNITY",
    Edit_Button = "Edit"
}

export enum Community_Joining_Que_Labels_Eng {
    Marraige_Purpose = "Marriage purpose",
    Buisness_Purpose = "Business purpose",
    Community_Purpose = "To join sanstha",
    JOb_Purpose = "To find job"
}

export enum Regisration_Form_Labels_English {
    BIRTH_DATE = "DATE OF BIRTH",
    GENDER = "GENDER",
    FATHERS_NAME = "FATHER'S NAME",
    MOTHERS_NAME = "MOTHER’S NAME",
    MARITAL_STATUS = "MARITAL STATUS",
    FULL_NAME = "FULL NAME",
    AADHAR_CARD_NUMBER = "AADHAR CARD NUMBER",
    AUTOFILL_ADDRESS = "Save time by auto-filling your current address",
    MOBILE_NUMBER = "MOBILE NUMBER",
    ALTERNATE_MOBILE_NUMBER = "ALTERNATE MOBILE NUMBER",
    EMAIL_ID = "EMAIL ID",
    ALTERNATE_EMAIL_ID = "ALTERNATE EMAIL ID",
    ADDRESS_LINE1 = "ADDRESS LINE 1",
    ADDRESS_LINE2 = "ADDRESS LINE 2",
    PIN_CODE = "PIN CODE",
    STATE = "STATE",
    COUNTRY = "COUNTRY",
    LAST_NAME = "LAST NAME",
    EDUCATIONAL_QUALIFICATION = "EDUCATIONAL QUALIFICATION",
    OCCUPATION = "OCCUPATION",
    EMPLOYER_NAME = "EMPLOYER NAME",
    COMPANY_SIZE = "COMPANY SIZE",
    BUSINESS_DETAILS = "BUSINESS DETAILS",
    POLICY = "I agree to all privacy policy of the organization",
    GO_BACK = "Go back",
    PROCEED = "Save & Proceed",
    SUBMIT = "Submit",
    SAVE = "Save as Draft",
    ORGANIZATION = "Organization",
}

export enum Stepper_labels_English {
    PERSONAL_DETAILS = "Personal Details",
    COMMUNICATION_DETAILS = "Communication Details",
    CONFIRMATION = "Confirmation"
}

export enum Profile_Tab_English {
    FAMILY = "Family",
    COMMUNICATION_DETAILS = "Communication Details",
    ABOUT = "About",
    ADDRESS = "Address",
    PROFESSIONAL_BACKGROUND = "Professional Background"
}

export enum PROFILE_HEADINGS_ENG {
    ORGANIZATION = "Organization",
    UNIVERSITY = "University",
    GRADUATION_YEAR = "Graduation Year",
    AGE = "Age",
    CURRENT_ADDRESS = "Current Address",
    PERMENANT_ADDRESS = "Permanent Address",
    ADD_FAMILY_MEMBER = "Add family member",
}

export enum ABOUT_ENG {
    BIO = "Bio",
    DOB = "Date of Birth",
    AGE = "Age",
    GENDER = "Gender",
    MARITAL_STATUS = "Marital Status",
    AADHAR = "Aadhar No."
}

export enum COMMUNICATION_DETAILS_ENG {
    MOBILE_NUMBER = "Mobile No.",
    EMAIL_ID = "Email ID",
    ALTERNATE_MOBILE_NUMBER = "Alternate Mobile No.",
    ALTERNATE_EMAIL_ID = "Alternate Email ID"
}

export enum PROFESSIONAL_BACKGROUND_ENG {
    OCCUPATION = "Occupation",
    ORGANIZATION = "Organization",
    EDUCATIONAL_QUALIFICATION = "Educational Qualification",
    UNIVERSITY = "University",
    GRADUATION_YEAR = "Year of Graduation",
    BUSINESS_DETAILS = "Business Details"
}

export enum LANDING_PAGE_LABELS_ENG {
    CELEBRATING_UNITY_AND_TRADITION = "Celebrating Unity & Tradition",
    ABOUT_OUR_COMMUNITY = "About Our Community",
    A_VAST_COMMUNITY_OF = "A vast community of",
    SANSTHAS = "Sansthas",
    MEMBERS = "Members",
    EVENTS = "Events"
}