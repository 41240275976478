import MKS_LOGO from "./mks-logo-no-bg.png";
import {ImageProps} from "@chakra-ui/icons";
import {Image} from "@chakra-ui/react";

const MksLogo = (props: ImageProps) => (
  <Image
      src={MKS_LOGO}
      height={props.height}
      width={props.width}
  />
);

export default MksLogo;
