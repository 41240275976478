const baseStyle = {
    fontFamily: "PP Neue Montreal",
    lineHeight: "100%",
};

const textStyles  = {
    captionAccent : {
        ...baseStyle,
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: 600,
    },
    captionBold : {
        ...baseStyle,
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: 700,
    },
    captionEmphasis : {
        ...baseStyle,
        fontSize: "10px",
        fontStyle: "italic",
        fontWeight: 400,
    },
    captionRegular : {
        ...baseStyle,
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: 400,
    },
    contentAccent : {
        ...baseStyle,
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 600,
    },
    contentBold : {
        ...baseStyle,
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 700,
    },
    contentEmphasis : {
        ...baseStyle,
        fontSize: "12px",
        fontStyle: "italic",
        fontWeight: 400,
    },
    contentRegular : {
        ...baseStyle,
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
    },
    featureAccent : {
        ...baseStyle,
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 600,
    },
    featureBold : {
        ...baseStyle,
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 700,
    },
    featureEmphasis : {
        ...baseStyle,
        fontSize: "18px",
        fontStyle: "italic",
        fontWeight: 400,
    },
    featureStandard : {
        ...baseStyle,
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 500,
    },
    highlightAccent : {
        ...baseStyle,
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 600,
    },
    highlightBold : {
        ...baseStyle,
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 700,
    },
    highlightEmphasis : {
        ...baseStyle,
        fontSize: "14px",
        fontStyle: "italic",
        fontWeight: 400,
    },
    highlightStandard : {
        ...baseStyle,
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
    },
    standardAccent: {
        ...baseStyle,
        fontSize: "22px",
        fontStyle: "normal",
        fontWeight: 500,
    },
    standardBold: {
        ...baseStyle,
        fontSize: "22px",
        fontStyle: "normal",
        fontWeight: 700,
    }
};

export default textStyles;