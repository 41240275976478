import * as React from "react";

import { JSX } from "react/jsx-runtime";

const InfoIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
  <svg
    width={27}
    height={26}
    viewBox="0 0 27 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="information-circle">
      <path
        id="Vector"
        d="M24.3332 13C24.3332 7.01693 19.4829 2.16669 13.4998 2.16669C7.51675 2.16669 2.6665 7.01693 2.6665 13C2.6665 18.9831 7.51675 23.8334 13.4998 23.8334C19.4829 23.8334 24.3332 18.9831 24.3332 13Z"
        stroke="black"
        strokeWidth={1.63}
      />
      <path
        id="Vector_2"
        d="M13.7625 18.4167V13C13.7625 12.4893 13.7625 12.234 13.6038 12.0753C13.4452 11.9167 13.1899 11.9167 12.6792 11.9167"
        stroke="black"
        strokeWidth={1.63}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M13.4917 8.66669H13.5015"
        stroke="black"
        strokeWidth={1.63}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
export default InfoIcon;
