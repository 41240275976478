import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { tabsAnatomy } from '@chakra-ui/anatomy'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys)

// define the base component styles
const baseStyle = definePartsStyle({
  // define the part you're going to style
    tab: {
        borderRadius: "14px",
        border: "1px solid rgba(108, 117, 125, 0.51)",
        background: "rgba(255, 255, 255, 0.39)",
        color: "#000000",
        padding: "16px 21px",
        height: {md: "60px", lg: "67px", xl: "67px"},
        fontWeight: "500",
        minWidth: {md: "4rem", lg: "9rem", xl: "10rem"},
        _selected: {
            background: "#4A2C2A",
            color: "white",
        },
    },
    tablist: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
    },
})

// export the component theme
export const tabsTheme = defineMultiStyleConfig({ baseStyle })