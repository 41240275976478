export enum HindiLabels {
    Mourya_Community_Label = "मौर्य कुशवाहा समाज, ठाणे.",
    Mourya_Community_Label2 = "हमारे डिजिटल समुदाय में आपका स्वागत है, जुड़ें और हमें सशक्त बनाएं.",
    Register = "रजिस्टर करें",
    Login = "लॉगिन करें",
    Login_Page = "प्रवेश करें",
    Welcome_Label = "मौर्य कुशवाहा समाज, ठाणे की डिजिटल दुनिया में आपका स्वागत है.",
    Proceed = "जारी रखें",
    Enter_Email = "ईमेल दर्ज करें",
    Dont_Have_Account = "कोई खाता नहीं है ?",
    Already_Have_Account = "क्या आपके पास पहले से एक खाता मौजूद है ?",
    Enter_Full_Name = "अपना पूरा नाम दर्ज करें.",
    Enter_Password = "पास वर्ड दर्ज करें",
    Confirm_Password = "पासवर्ड की पुष्टि कीजिये",
    Enter_DOB = "अपनी जन्मतिथि चुनें.",
    Community_Joining_Que = "समुदाय से जुड़ने में रुचि",
    Edit_Button = "संपादन करना"
}

export enum Community_Joining_Que_Labels_Hindi {
    Marraige_Purpose = "शादी के लिए",
    Buisness_Purpose = "व्यापार से संबंधित",
    Community_Purpose = "संस्था में शामिल होने के लिए",
    JOb_Purpose = "नौकरी खोजने के लिए"
}

export enum Regisration_Form_Labels_Hindi {
    BIRTH_DATE = "जन्मतिथि",
    GENDER = "लिंग",
    FATHERS_NAME = "पिता का नाम",
    MOTHERS_NAME = "मां का नाम",
    MARITAL_STATUS = "वैवाहिक स्थिति",
    FULL_NAME = "पूरा नाम",
    AADHAR_CARD_NUMBER = "आधार कार्ड नंबर",
    AUTOFILL_ADDRESS = "अपना वर्तमान पता स्वतः भरकर समय बचाएं",
    MOBILE_NUMBER = "दूरध्वनी क्रमांक",
    ALTERNATE_MOBILE_NUMBER = "वैकल्पिक दूरध्वनी क्रमांक",
    EMAIL_ID = "ईमेल आईडी",
    ALTERNATE_EMAIL_ID = "वैकल्पिक ईमेल आईडी",
    ADDRESS_LINE1 = "स्थानीय पत्ता",
    ADDRESS_LINE2 = "स्थायी पत्ता",
    PIN_CODE = "पिनकोड",
    STATE = "राज्य",
    COUNTRY = "देश",
    LAST_NAME = "उपनाम",
    EDUCATIONAL_QUALIFICATION = "शैक्षणिक योग्यता",
    OCCUPATION = "व्यवसाय",
    EMPLOYER_NAME = "नियोक्ता का नाम",
    COMPANY_SIZE = "संग का आकार",
    BUSINESS_DETAILS = "व्यवसाय विवरण",
    POLICY = "मैं संगठन की सभी गोपनीयता नीति से सहमत हूं",
    GO_BACK = "पीछे जाये",
    PROCEED = "आगे बढे",
    SUBMIT = "जमा करे",
    SAVE = "सुरक्षित करे",
    ORGANIZATION = "संगठन",
}

export enum Stepper_labels_Hindi {
    PERSONAL_DETAILS = "व्यक्तिगत विवरण",
    COMMUNICATION_DETAILS = "संचार विवरण",
    CONFIRMATION = "पुष्टीकरण"
}

export enum Admin_Labels_Hindi {
    PENDING_APPROVAL_REQUESTS = "लंबित अनुमोदन अनुरोध",
    APPROVE_ALL = "सभी को स्वीकृत करें",
    REJECT_ALL = "सभी अस्वीकार करें",
    APPROVE = "मंज़ूरी देना",
    REJECT = "अस्वीकार करना",
    NAME = "नाम",
    REASON_FOR_JOINING = "शामिल होने का कारण",
    MOBILE_NO = "मोबाइल नंबर",
    DOB = "जन्म तिथि",
    ACTION = "कार्रवाई"
}


export enum Profile_Tab_Hin {
    FAMILY = "परिवार",
    COMMUNICATION_DETAILS = "संचार विवरण",
    ABOUT = "के बारे में",
    ADDRESS = "पता",
    PROFESSIONAL_BACKGROUND = "व्यावसायिक पृष्ठभूमि"
}

export enum Abot_Headings_Hin {
    DOB = "जन्म तिथि",
    AGE = "आयु",
    GENDER = "लिंग",
    MARITIAL_STATUS = "वैवाहिक स्थिति",
    ADDHAR_NO = "आधार नं."
}

export enum Address_Heading_Hin {
    CURRENT_ADDRESS = "वर्त्तमान पता",
    PERMENANT_ADDRESS = "स्थायी पता"
}

export enum Communication_Heading_Hin {
    MOBILE_NUMBER = "संपर्क नंबर",
    ALTERNATE_MOBILE_NUMBER = "वैकल्पिक संपर्क नं.",
    EMAIL_ID = "ईमेल आईडी",
    ALTERNATE_EMAIL_ID = "वैकल्पिक ईमेल आईडी"
}

export enum PROFILE_HEADINGS_HIN {
    ORGANIZATION = "संगठन",
    UNIVERSITY = "विश्वविद्यालय",
    GRADUATION_YEAR = "स्नातक वर्ष",
    AGE = "आयु",
    CURRENT_ADDRESS = "वर्त्तमान पता",
    PERMENANT_ADDRESS = "स्थायी पता",
    ADD_FAMILY_MEMBER = "परिवार के सदस्य जोड़ें",
    BIO = "जीवन वृत्त"
}

export enum LANDING_PAGE_LABELS_HIN {
    CELEBRATING_UNITY_AND_TRADITION = "एकता और परंपरा का जश्न",
    ABOUT_OUR_COMMUNITY = "हमारी समुदाय के बारे में",
    A_VAST_COMMUNITY_OF = "एक विशाल समुदाय का",
    SANSTHAS = "संस्थाएँ",
    MEMBERS = "सदस्य",
    EVENTS = "आयोजन"
}