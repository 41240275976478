import {Flex, Grid, GridItem, HStack, Image, Text, VStack, useMediaQuery} from "@chakra-ui/react";

import { LANDING_PAGE_LABELS_ENG } from "src/common/constants/englishLabels";
import { LANDING_PAGE_LABELS_HIN } from "src/common/constants/hindiLabels";
import { RootState } from "src/store/store";
import about_1 from "../../../resources/images/about_1.jpg";
import about_2 from "../../../resources/images/about_2.jpg";
import about_3 from "../../../resources/images/about_3.jpg";
import community_1 from "../../../resources/images/community_1.jpg";
import community_2 from "../../../resources/images/community_2.jpg";
import community_3 from "../../../resources/images/community_3.jpg";
import { useSelector } from "react-redux";

const AboutCommunity: React.FC = () => {
    const [isMobile] = useMediaQuery("(max-width: 426px)");
    const [isTablet] = useMediaQuery("(min-width: 426px) and (max-width: 768px)");
    const globalInfo = useSelector((state: RootState) => state.globalInfo);
    const { globalLanguage } = globalInfo;
    const isHindiLabels = globalLanguage === "HI";

    return (
        <VStack h="100%" w="100%">
            <Flex direction={(isMobile || isTablet) ? "column" : "row"} justifyContent="space-between" px={10} py={{base:"5rem", md:"7rem", lg:"7.5rem"}}>
                <VStack alignItems="start" w={(isMobile || isTablet) ? "100%" : "45%"}>
                    <Text
                        color="#08090A"
                        fontSize="32px"
                        fontWeight={700}
                    >
                        {isHindiLabels ? LANDING_PAGE_LABELS_HIN.ABOUT_OUR_COMMUNITY : LANDING_PAGE_LABELS_ENG.ABOUT_OUR_COMMUNITY}
                    </Text>
                    <Text
                        color="#6C757D"
                        fontSize="18px"
                        fontWeight={500}
                    >
                        Lorem ipsum dolor sit amet consectetur. Integer facilisis interdum enim tincidunt at ullamcorper morbi cursus blandit. Turpis nisi sed purus sed faucibus adipiscing fringilla ut. Quisque pulvinar erat viverra eu nulla. Est non tempus aliquam vestibulum fringilla vitae ligula magna arcu. Faucibus mus at pharetra pellentesque ut in dignissim. In nec ut sit leo aenean ut diam. In ultrices id sed egestas. Duis ornare quis erat leo.
                    </Text>
                    <Text
                        color="#6C757D"
                        fontSize="18px"
                        fontWeight={500}
                    >

                        Justo amet nisi neque tortor ut natoque. Cras vitae maecenas pulvinar nunc in euismod. Dignissim sed massa quisque nibh malesuaCursus scelerisque proin pellentesque ornare pellentesque amet vitae sed.
                    </Text>
                    <Text
                        color="#6C757D"
                        fontSize="18px"
                        fontWeight={500}
                    >

                        Justo amet nisi neque tortor ut natoque. Cras vitae maecenas pulvinar nunc in euismod. Dignissim sed massa quisque nibh malesuada aliquet neque amet ut. Purus arcu velit nullam eget vitae nibh... more
                    </Text>
                </VStack>
                <VStack w={(isMobile || isTablet) ? "100%" : "45%"} mt={(isMobile || isTablet) ? 10 : 0}>
                    <Grid h={{base:"220px", lg:"350px"}} templateRows="repeat(2, 1fr)" templateColumns="repeat(2, 1fr)" gap={(isMobile || isTablet) ? 1 : 5}>
                        <GridItem colSpan={1}>
                            <Image src={about_1} alt="About Image 1" objectFit={"cover"} boxSize={"100%"} borderRadius={12} maxH={(isMobile || isTablet) ? "100%" : "165px"} />
                        </GridItem>
                        <GridItem rowSpan={2} colSpan={1}>
                            <Image src={about_3} alt="About Image 2" objectFit={"cover"} boxSize={"100%"} borderRadius={12} maxH={"350px"}/>
                        </GridItem>
                        <GridItem colSpan={1}>
                            <Image src={about_2} alt="About Image 3" objectFit={"cover"} boxSize={"100%"} borderRadius={12} maxH={(isMobile || isTablet) ? "100%" : "165px"}/>
                        </GridItem>
                    </Grid>
                </VStack>
            </Flex>
            <HStack background={"#6E5655"} justifyContent="space-between" gap={10} h="100%" w="100%" px={10} py={{base:"5rem", md:"7rem", lg:"7.5rem"}}>
                <VStack hidden={isMobile || isTablet} alignItems="start" w="45%">
                    <Grid h="350px" templateRows="repeat(2, 1fr)" templateColumns="repeat(2, 1fr)" gap={5}>
                        <GridItem colSpan={1}>
                            <Image src={community_1} alt="Community Image 1" objectFit={"cover"} boxSize={"100%"} borderRadius={12} />
                        </GridItem>
                        <GridItem rowSpan={2} colSpan={1}>
                            <Image src={community_3} alt="Community Image 2" objectFit={"cover"} boxSize={"100%"} borderRadius={12}/>
                        </GridItem>
                        <GridItem colSpan={1}>
                            <Image src={community_2} alt="Community Image 3" objectFit={"cover"} boxSize={"100%"} borderRadius={12}/>
                        </GridItem>
                    </Grid>
                </VStack>
                <VStack w={(isMobile || isTablet) ? "100%" : "45%"} alignItems="end" fontSize={{base:"38px", md:"48px", lg:"54px"}} fontWeight={400}>
                    <Text fontWeight={500} fontSize="28px" color="var(--Base-Base-White, #FFF)">{isHindiLabels? LANDING_PAGE_LABELS_HIN.A_VAST_COMMUNITY_OF : LANDING_PAGE_LABELS_ENG.A_VAST_COMMUNITY_OF}</Text>
                    <HStack>
                        <Text fontWeight={700} fontSize={{base:"38px", md:"48px", lg:"54px"}} color="#F93">154890</Text>
                        <Text color="var(--Base-Base-White, #FFF)" ml={2}>{isHindiLabels? LANDING_PAGE_LABELS_HIN.MEMBERS : LANDING_PAGE_LABELS_ENG.MEMBERS}</Text>
                    </HStack>
                    <HStack>
                        <Text fontWeight={700} fontSize={{base:"38px", md:"48px", lg:"54px"}} color="#F93">300+</Text>
                        <Text color="var(--Base-Base-White, #FFF)" ml={2}>{isHindiLabels? LANDING_PAGE_LABELS_HIN.EVENTS : LANDING_PAGE_LABELS_ENG.EVENTS}</Text>
                    </HStack>
                </VStack>
            </HStack>
        </VStack>
    );
};

export default AboutCommunity;