import { AppDispatch, RootState } from 'src/store/store';
import { Flex, Select } from '@chakra-ui/react'
import { useDispatch, useSelector } from "react-redux";

import GlobeIcon from '../resources/icons/GlobeIcon'
import React from 'react'
import { setGlobalInfo } from 'src/store/features/global';

export const GlobalLangSelector: React.FC = () => {
    const globalInfo = useSelector((state: RootState) => state.globalInfo);
    const dispatch = useDispatch<AppDispatch>();
    const { globalLanguage } = globalInfo;

    const changeGlobalLanguage = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const { value } = event.target;
        window.sessionStorage.setItem("globalLanguage", value ?? "EN");
        dispatch(setGlobalInfo({
            globalLanguage: value ?? "EN",
        }));
    }
    return (
        <>
            <Flex alignItems="center">
                <GlobeIcon />
                <Select color="black" width="5rem" borderColor="transparent" onChange={changeGlobalLanguage} value={globalLanguage}>
                    <option value='EN'>EN</option>
                    <option value='HI'>हिंदी</option>
                </Select>
            </Flex>
        </>
    )
}

