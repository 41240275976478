const colors = {
    "base-black" : "#0A0A0B" ,
    "base-white" : "#FFFFFF" ,
    "error" : "#F64040" ,
    "error.100" : "#FDD9D9" ,
    "error.200" : "#FBB3B3" ,
    "error.300" : "#FA8C8C" ,
    "error.400" : "#F86666" ,
    "error.450" : "#F93565" ,
    "error.500" : "#F41E1E" ,
    "error.600" : "#E40B0B" ,
    "error.700" : "#C20909" ,
    "error.800" : "#A00808" ,
    "error.900" : "#7E0606" ,
    "error.1000" : "#5C0404" ,
    "nav-icon-dark": "#807AD5",
    "neutral.100" : "#E7E7E7" ,
    "neutral.200" : "#D0CFCF " ,
    "neutral.300" : "#B9B7B7" ,
    "neutral.400" : "#A29F9F" ,
    "neutral.500" : "#8B8686" ,
    "neutral.600" : "#746E6E" ,
    "neutral.700" : "#5C5757" ,
    "neutral.800" : "#444040" ,
    "neutral.900" : "#2C292A" ,
    "neutral.1000" : "#151314" ,
    "neutral.1100" : "#121B22",
    "primary" : "#4F20B8" ,
    "primary.100" : "#ECE6FB" ,
    "primary.200" : "#DACDF6" ,
    "primary.300" : "#C7B4F2" ,
    "primary.400" : "#B49BEE" ,
    "primary.500" : "#A282E9" ,
    "primary.600" : "#8F69E5" ,
    "primary.700" : "#7C50E1" ,
    "primary.700.dark" : "#3E3E6E" ,
    "primary.800" : "#6A37DC" ,
    "primary.900" : "#5A24D1" ,
    "primary.1000" : "#461CA4" ,
    "primary.1100" : "#3E198F" ,
    "primary.1200" : "#35157B" ,
    "primary.1300" : "#2C1267" ,
    "primary.1400" : "#230E53" ,
    "primary.1500" : "#1B0B3E" ,
    "secondary" : "#C1C1C1" ,
    "secondary.100" : "#F0F0F0" ,
    "secondary.200" : "#E0E0E0" ,
    "secondary.300" : "#D1D1D1" ,
    "secondary.400" : "#ADADAD" ,
    "secondary.500" : "#999" ,
    "secondary.600" : "#858585" ,
    "secondary.700" : "#717171" ,
    "secondary.800" : "#5D5D5D" ,
    "secondary.900" : "#494949" ,
    "secondary.1000" : "#353535" ,
    "side-nav-bg-dark": "#2D2E56",
    "side-nav-bg-light": "#EDEBEB",
    "success" : "#0FC453" ,
    "success.100" : "#D7FCE5" ,
    "success.200" : "#B0F9CB" ,
    "success.300" : "#88F6B1" ,
    "success.400" : "#60F397" ,
    "success.500" : "#39F07D" ,
    "success.600" : "#12EC64" ,
    "success.700" : "#0DA747" ,
    "success.800" : "#0B8B3B" ,
    "success.900" : "#086E2F" ,
    "success.1000" : "#065122" ,
    "warning" : "#FF9900" ,
    "warning.100" : "#FFF0DB" ,
    "warning.200" : "#FFE2B6" ,
    "warning.300" : "#FFD392" ,
    "warning.400" : "#FFC56D" ,
    "warning.500" : "#FFB649" ,
    "warning.600" : "#FFA824" ,
    "warning.700" : "#D98200" ,
    "warning.800" : "#B36B00" ,
    "warning.900" : "#8C5400" ,
    "warning.1000" : "#663D00"
};

export default colors;