const spacing = {
    space: {
        0.5: "0.125rem",
        1: "0.25rem",
        1.5: "0.375rem",
        2: "0.5rem",
        2.5: "0.625rem",
        "2xl": "2.75rem",
        3: "0.75rem",
        3.5: "0.875rem",
        "3xl": "3rem",
        4: "1rem",
        "4xl": "3.25rem",
        5: "1.25rem",
        "5xl": "3.5rem",
        6: "1.5rem",
        "6xl": "3.75rem",
        7: "1.75rem",
        "7xl": "4rem",
        8: "2rem",
        "8xl": "4.25rem" ,
        9: "2.25rem",
        "9xl": "4.5rem",
        10: "2.5rem",
        12: "3rem",
        14: "3.5rem",
        16: "4rem",
        20: "5rem",
        24: "6rem",
        28: "7rem",
        32: "8rem",
        36: "9rem",
        40: "10rem",
        44: "11rem",
        48: "12rem",
        52: "13rem",
        56: "14rem",
        60: "15rem",
        64: "16rem",
        72: "18rem",
        80: "20rem",
        96: "24rem",
        "base": "1rem",
        "l": "2rem",
        "m" : "1rem" ,
        "ml" : "1.5rem",
        "px": "1px",
        "s": "0.5rem",
        "sm": "0.75rem",
        "xl": "2.25rem",
        "xs": "0.375rem",
        "xxs": "0.125rem",
    },
};

export default spacing;