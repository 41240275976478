import { Box, Button, Card, CardBody, CardFooter, HStack, Heading, Image, Text, VStack } from '@chakra-ui/react'
import { InfoIcon, MapIcon } from 'src/resources/icons'

import EVENT_IMAGE from "../../../../resources/icons/event-image.svg";
import React from 'react'
import USER_GROUP from "../../../../resources/icons/user-group.svg"

export const SansthaCard: React.FC = () => {
    return (
        <Card
            borderRadius="16px"
            boxShadow= "0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 8px 16px 0px rgba(0, 0, 0, 0.08)"
            minHeight="500px"
            maxHeight="550px"
            sx={{
                '@media screen and (max-width: 375px)': {
                    minWidth: '300px',
                    maxWidth: '300px',
                },
                '@media screen and (min-width: 376px)': {
                    minWidth: '350px',
                    maxWidth: '350px',
                },
            }}
        >
            <Box width="100%" position="relative" display="inline-block">
                <Image
                    width="100%"
                    src={EVENT_IMAGE}
                    alt="Event Image"
                />
            </Box>
            <CardBody gap="2" justifyContent="flex-start">
                <Heading fontSize="24px" mb="20px">    
                    Thane Main Sanstha 
                </Heading>
                <VStack alignItems="flex-start" spacing="12px">
                    <HStack>
                        <Box ml="0.5rem" height="1rem" width="1rem">
                            <Image
                                height="100%"
                                width="100%"
                                src={USER_GROUP}
                                alt='user group'
                            />
                        </Box>
                        <Text>200 persons</Text>
                    </HStack>
                    <HStack>
                        <InfoIcon/>
                        <Text>Founded in 2001</Text>
                    </HStack>
                    <HStack>
                        <MapIcon/>
                        <Text>CB90 Salt Lake, Sector 1, Kolkata, West Bengal, India, 700064</Text>
                    </HStack>
                </VStack>
            </CardBody>
            <CardFooter width="100%" justifyContent="space-between" alignItems="center">
                <Button backgroundColor="white" padding="12px 28px" borderRadius="10px" border="1px solid #4A2C2A">View</Button>
            </CardFooter>
        </Card>
    )
}
