import { Box, Button, Card, CardBody, CardFooter, HStack, Heading, Image, Text, VStack } from '@chakra-ui/react'
import { CalenderIcon, InfoIcon, MapIcon } from 'src/resources/icons'

import CLOCK_ICON from "../../../../resources/icons/clock-05.svg";
import EVENT_IMAGE from "../../../../resources/icons/event-image.svg";
import React from 'react'

export const EventCard: React.FC = () => {
    return (
        <Card
            borderRadius="16px"
            boxShadow= "0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 8px 16px 0px rgba(0, 0, 0, 0.08)"
            minHeight="500px"
            maxHeight="550px"
            sx={{
                '@media screen and (max-width: 375px)': {
                    minWidth: '300px',
                    maxWidth: '300px',
                },
                '@media screen and (min-width: 376px)': {
                    minWidth: '350px',
                    maxWidth: '350px',
                },
            }}
        >
            <Box width="100%" position="relative" display="inline-block">
                <Image
                    width="100%"
                    src={EVENT_IMAGE}
                    alt="Event Image"
                />
                <Box
                    bottom="23px"
                    right="23px"
                    width="65px"
                    height="65px"
                    flexShrink="0"
                    borderRadius="8px"
                    backgroundColor="white"
                    position="absolute"
                >
                    <Text
                        color="#4A2C2A"
                        fontSize="24px"
                        fontWeight="700"
                        alignSelf="stretch"
                        height="30px"
                        textAlign="center"
                    >
                        07
                    </Text>
                    <Text
                        color="rgba(74, 44, 42, 0.70)"
                        fontSize="24px"
                        fontWeight="700"
                        alignItems="center"
                        textAlign="center"
                    >
                        OCT
                    </Text>
                </Box>
            </Box>
            <CardBody gap="2" justifyContent="flex-start">
                <Heading fontSize="24px" mb="20px">    
                        Ecstasy of Enlightenment at Life Journey
                </Heading>
                <VStack alignItems="flex-start" spacing="12px">
                    <HStack>
                        <InfoIcon/>
                        <Text>In Person</Text>
                    </HStack>
                    <HStack>
                        <CalenderIcon/>
                        <Text>07 Oct - 08 Oct, 2024</Text>
                    </HStack>
                    <HStack>
                        <MapIcon/>
                        <Text>CB90 Salt Lake, Sector 1, Kolkata, West Bengal, India, 700064</Text>
                    </HStack>
                </VStack>
            </CardBody>
            <CardFooter width="100%" justifyContent="space-between" alignItems="center">
                <Image
                    src={CLOCK_ICON}
                    alt="Clock Image"
                />
                <Text fontSize="14px" color="#FF7E6B" fontWeight="500">Registration ends in 22 hrs</Text>
                <Button backgroundColor="white" padding="12px 28px" borderRadius="10px" border="1px solid #4A2C2A">Register</Button>
            </CardFooter>
        </Card>
    )
}
