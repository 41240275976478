import React from 'react'

const GlobeIcon: React.FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
            <path d="M12 22.5C17.5228 22.5 22 18.0228 22 12.5C22 6.97715 17.5228 2.5 12 2.5C6.47715 2.5 2 6.97715
             2 12.5C2 18.0228 6.47715 22.5 12 22.5Z" stroke="#FF7E6B" strokeWidth="1.5" />
            <path d="M8 12.5C8 18.5 12 22.5 12 22.5C12 22.5 16 18.5 16 12.5C16 6.5 12 2.5 12 2.5C12 2.5 8 6.5 8 12.5Z"
             stroke="#FF7E6B" strokeWidth="1.5" strokeLinejoin="round" />
            <path d="M21 15.5H3" stroke="#FF7E6B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M21 9.5H3" stroke="#FF7E6B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default GlobeIcon;