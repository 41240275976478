import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./carousel.scss";

import React from "react";
import Slider from 'react-slick';

type CarouselProps = {
    setCurrentSlide: (slideNumber: number) => void;
    sliderRef : React.RefObject<Slider>;
    CardComponent: React.FC;
    slidesToShow: number
}

export const Carousel: React.FC<CarouselProps> = ({ CardComponent, sliderRef, setCurrentSlide, slidesToShow }) => {

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                slidesToShow: 1,
                },
            },
        ],
        arrows: false,

        afterChange: (current: number) => setCurrentSlide(current),
    };
    
    return (
        <Slider ref={sliderRef} {...settings}>
            {Array.from({ length: 5 }).map((_, index) => (
                <div key={`${index}-index`}>
                    <CardComponent/>
                </div>
                ))}
        </Slider>
    );
} 