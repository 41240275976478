import * as React from "react";

import { JSX } from "react/jsx-runtime";

const MapIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
  <svg
    width={27}
    height={26}
    viewBox="0 0 27 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="maps-circle-01">
      <path
        id="Vector"
        d="M16.8892 14.8924C16.7066 15.0683 16.4624 15.1667 16.2082 15.1667C15.9541 15.1667 15.7099 15.0683 15.5273 14.8924C13.8543 13.2713 11.6122 11.4604 12.7056 8.83119C13.2968 7.40963 14.7158 6.5 16.2082 6.5C17.7006 6.5 19.1197 7.40963 19.7109 8.83119C20.8029 11.457 18.5663 13.2768 16.8892 14.8924Z"
        stroke="black"
        strokeWidth={1.625}
      />
      <path
        id="Vector_2"
        d="M16.2085 10.2917H16.2175"
        stroke="black"
        strokeWidth={2.16667}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M24.3332 13C24.3332 18.9831 19.4829 23.8334 13.4998 23.8334C7.51675 23.8334 2.6665 18.9831 2.6665 13C2.6665 7.01693 7.51675 2.16669 13.4998 2.16669C19.4829 2.16669 24.3332 7.01693 24.3332 13Z"
        stroke="black"
        strokeWidth={1.625}
      />
      <path
        id="Vector_4"
        d="M10.25 16.25L5.91667 20.5833M16.75 22.75L3.75 9.75"
        stroke="black"
        strokeWidth={1.625}
        strokeLinecap="round"
      />
    </g>
  </svg>
);
export default MapIcon;
