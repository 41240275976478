import { Button, Drawer, DrawerBody, DrawerContent, DrawerOverlay, VStack } from "@chakra-ui/react";

import { NavMenu } from "./homeNavbar";
import { memo } from "react";

type SideDrawerProps = {
    sideDrawerOpen: boolean;
    onClose: () => void;
    menu: NavMenu[]
    activeSection: string;
    handleNavClick: (nav: NavMenu) => void
}
const SideDrawer = memo(({ sideDrawerOpen, onClose, menu, activeSection, handleNavClick }: SideDrawerProps) => {
    return (
        <Drawer onClose={onClose} isOpen={sideDrawerOpen} size="full">
            <DrawerOverlay 
                top="5rem"
            />
            <DrawerContent
                marginTop="5rem"
                background="rgba(255, 188, 120, 0.06)"
            >
                <DrawerBody>
                    <VStack
                        height="50%"
                        justifyContent="space-between"
                    >
                        {menu.map((nav) =>
                            <Button
                                className="navbar-buttons"
                                backgroundColor={nav.name === activeSection ? "#4A2C2A" : "white"}
                                color={nav.name === activeSection ? "white" : "#4A2C2A"}
                                onClick={() => handleNavClick(nav)}
                                fontWeight={500}
                                _hover={{
                                    backgroundColor: nav.name === activeSection ? "#4A2C2A" : "white",
                                    color: nav.name === activeSection ? "white" : "#4A2C2A",
                                    transform: 'scale(1.05)',
                                    transition:"transform 0.3s ease, opacity 0.3s ease"
                                }}
                                _active={{
                                    backgroundColor: nav.name === activeSection ? "#4A2C2A" : "white",
                                    color: nav.name === activeSection ? "white" : "#4A2C2A",
                                }}
                            >
                                {nav.name}
                            </Button>
                        )}
                    </VStack>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
});

export default SideDrawer;
